<template>
    <div class="video-box">
        <video ref="videoPlayer" class="video-js" ></video>
    </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default {
    name: "Video",
    props: ['src'],
    data() {
        return {
            player: null
        };
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, {
            playbackRates: [0.5, 1.0, 1.5, 2.0],
            controls: true,
            autoplay: true,
            muted: false,
            fluid: true,
            sources: [{
                src: this.src,
                type: 'video/mp4'
            }
            ]
        }, () => {
            this.player.log('onPlayerReady', this);
            document.addEventListener('keyup',this.keyup,false) 
        });
        
    },
    beforeDestroy() {
        document.removeEventListener('keyup',this.keyup,false)
        if (this.player) {
            this.player.dispose();
        }
    },
    methods: {
        keyup(e) {
            e.preventDefault()
            e.stopPropagation()
            
            let vol = 0.1;  //1代表100%音量，每次增减0.1
            let time = 5; //单位秒，每次增减10秒

            console.log(this.player.volume())
            // 按向上键
            if (e.keyCode === 38) {
                let volumn = this.player.volume()
                volumn += vol
                volumn = Math.min(1,volumn)
                this.player.volume(volumn)
                return false;
            }
            // 按向下键
            if (e.keyCode === 40) {
                let volumn = this.player.volume()
                volumn -= vol
                volumn = Math.max(0,volumn)
                this.player.volume(volumn)
                return false;
            }
            // 按左键
            if (e.keyCode === 37) {
                let currentTime = this.player.currentTime()
                currentTime -= time
                currentTime = Math.max(0,currentTime)
                this.player.currentTime(currentTime)
                return false;
            }
            // 按右键
            if (e.keyCode === 39) {
                let currentTime = this.player.currentTime()
                currentTime += time
                this.player.currentTime(currentTime)
                return false;
            }
            // 按空格
            if (e.keyCode === 32) {
                this.player.paused() === true ? this.player.play() : this.player.pause();
                return false;
            }
        },
    }
};
</script>

<style lang="scss">
.video-box{
    width: 100%;
    height: 500px;
    .video-js{
        width: 100%;
        height: 100%;
        .vjs-big-play-button {
            /*对播放按钮的样式进行设置*/
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}

</style>

