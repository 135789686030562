<template>
    <b-card :title="`${$t('Material Name')}：${activeData.name}.${activeData.suffix}`">
		<!-- 图片 -->
        <b-img
			:src="url"
			fluid-grow
			:alt="`${activeData.name}.${activeData.suffix}`"
			v-if="activeData.type==0"
		/>
		<!-- 视频 -->
		<VideoPlayer
			:src="url"
			v-else-if="activeData.type==1"></VideoPlayer>
		
		<!-- 文本 -->
		<div class="text-box p-2" v-html="text" v-else-if="activeData.type==2"></div>

		<!-- 音频 -->
		<audio loop="loop"
			draggable="false"
			controls
			:src="url"
			v-else-if="activeData.type==3"
		></audio>
    </b-card>
</template>
<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BImg } from 'bootstrap-vue'
import VideoPlayer from "./VideoPlayer"
import { getStorage, removeStorage } from "@/libs/utils"
import config from "@/libs/config"
export default {
    components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
        BImg,

		VideoPlayer
	},
	computed: {
		url () {
			if(this.activeData && this.activeData.md5){
				return this.fullUrl(this.activeData.md5, this.activeData.suffix)
			}
			return ''
		}
	},
	data () {
		return {
			id: this.$route.query.id,
			activeData: {},
			text:''
		}
	},
	created () {
		if(!this.id){
			this.$router.back()
		}
		const activeData = getStorage("ACTIVE_MATERIAL")
		if(!activeData){
			this.$router.back()
		}
		this.activeData = activeData
		if(activeData.type==2){
			this.getText ()
		}
	},
	beforeDestroy() {
		removeStorage('ACTIVE_MATERIAL')
	},
	methods: {
		fullUrl(md5, suffix) {
			return config.ossUrl + md5 + "." + suffix;
		},
		getText (){
			this.$http.get(this.url).then(res=>{
                let words = res.data.split('\n').map(v => v.trim())
                words = words.map(item=>{
                    return '<p>'+item+'</p>'
                })
				this.text = words.join('')
            }).catch(err=>{
                console.log(err)
            })
		}
	}
}
</script>
<style lang="scss" scoped>
.text-box{
	border: 1px solid #eee;
	border-radius: 10px;
}
</style>